/**************************** Custom Font Families --- */
@font-face {
  font-family: "Lato-Regular";
  src: url(../fonts/Lato-Regular.ttf);
}
@font-face {
  font-family: "Lato-Light";
  src: url(../fonts/Lato-Light.ttf);
}
@font-face {
  font-family: "Lato-Bold";
  src: url(../fonts/Lato-Bold.ttf);
}
@font-face {
  font-family: "Lato-Thin";
  src: url(../fonts/Lato-Thin.ttf);
}
@font-face {
  font-family: "Lato-Black";
  src: url(../fonts/Lato-Black.ttf);
}
/**************************** End Custom Font Families --- */

/******************************************** General CSS --- */
body {
  font-family: "Lato-Bold";
  font-size: 18px;
  color: #000000;
  width: 100%;
}
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato-Bold";
}
.h1,
h1 {
  font-size: 48px;
  font-family: "Lato-Bold";
}
.h2,
h2 {
  font-size: 50px;
  font-family: "Lato-Bold";
}
.remove-blank-space {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}
.h3,
h3 {
  font-size: 35px;
}
.h6,
h6 {
  font-size: 14px;
  letter-spacing: 2px;
}
a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}
.full-img {
  width: 100%;
}
.mt-120 {
  margin-top: 120px;
}
.mw-300 {
  max-width: 300px;
  width: 100%;
}
.mt-50 {
  margin-top: 50px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-150 {
  margin-top: 150px;
}
.p-relative {
  position: relative;
}
.mb-150 {
  margin-bottom: 150px;
}
.pt-15 {
  padding-top: 15px;
}
/* --- End General CSS --- */

/***************************** Header **/
.header-bg {
  width: 100%;
  height: 71%;
  overflow: hidden;
  position: absolute;
  margin: 0;
}
.header-bg:after {
  content: "";
  width: 142%;
  height: 142%;
  position: absolute;
  left: -20%;
  top: -75%;
  transform: rotate(-6deg) translate(0%, 0);
  background-image: linear-gradient(to right, #d9e9ea, #80b5b8);
  overflow: hidden;
}
.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff;
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.navbar-expand-lg .navbar-nav .nav-link:hover {
  text-decoration: underline;
}
a.login-btn {
  background: rgb(244 244 241 / 70%);
  color: #9bc4c6;
  padding: 8px 50px;
  border-radius: 10px;
  margin-left: 40px;
}
a.login-btn:hover {
  background: #f4f4f1;
}
.navbar-toggler-icon {
  background-image: url(../img/menu.svg);
}
section.platform {
  background-image: linear-gradient(to right, #d9e9ea, #80b5b8);
  position: relative;
  padding-bottom: 350px;
}
section.platform::before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: -1px;
  width: 100%;
  height: 45%;
  background: conic-gradient(
    at 0% 0%,
    transparent 102deg,
    white 0,
    #ffffff 271deg,
    transparent 0
  );
  transform: rotate(180deg);
}
section.peneeg {
  margin-top: -350px;
}

/*********************************** Founder section **/
.teamtitle {
  margin-bottom: 45px;
}
h3.member {
  margin-top: 30px;
}
section.founder-sec {
  background-image: linear-gradient(to right, #d9e9ea, #80b5b8);
  position: relative;
  padding-bottom: 100px;
  padding-top: 200px;
}
section.founder-sec::before {
  content: "";
  background-color: #fff;
  position: absolute;
  top: -1px;
  width: 100%;
  height: 20%;
  background: conic-gradient(
    at 0% 100%,
    transparent 80deg,
    white 0,
    #ffffff 90deg,
    transparent 0
  );
  transform: rotate(180deg);
}
/************************** Collaborators **/
.logos-partners {
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
}
.logos-partners img {
  max-height: 75px;
  padding: 0px 30px;
  max-width: 100%;
  margin-bottom: 20px;
}
/****************************** Cintact Us **/
.contact-us form label {
  width: 100%;
  font-size: 1.5em;
}
.contact-us form input#name,
.contact-us form input#email {
  width: 100%;
  height: 60px;
  border: 0;
  outline: 0px !important;
  padding: 10px 30px;
  border-radius: 10px;
  background: #f4f4f1;
  box-shadow: 0px 0px 5px #0000000d;
}
.contact-us form textarea#message {
  width: 100%;
  border: 0;
  outline: 0px !important;
  padding: 20px 30px;
  border-radius: 10px;
  background: #f4f4f1;
  box-shadow: 0px 0px 5px #0000000d;
}
.contact-us form {
  background: rgb(244 244 241 / 70%);
  padding: 50px;
  border-radius: 70px;
  box-shadow: 0px 0px 15px #5a5a5a2e;
}
input.form-btn {
  padding: 12px 60px;
  font-size: 1.5em;
  border: 0px;
  border-radius: 15px;
  background: #abcdcf;
  color: #fff;
  font-family: "Lato-Regular";
  -moz-box-shadow: inset 0 0 20px #00000026;
  -webkit-box-shadow: inset 0 0 20px #00000026;
  box-shadow: inset 0 0 20px #00000026;
  cursor: pointer;
}
.formtitle {
  margin-bottom: 30px;
}
input.form-btn:hover {
  -moz-box-shadow: inset 0 0 10px #00000026;
  -webkit-box-shadow: inset 0 0 10px #00000026;
  box-shadow: inset 0 0 10px #00000026;
}

/***************** Media Queries **/
@media only screen and (min-width: 992px) and (max-width: 1200px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    color: #fff;
    padding-right: 1.5rem;
    padding-left: 1.5rem;
  }
}
@media (max-width: 991px) {
  li.nav-item {
    border-bottom: 1px solid rgb(244 244 241 / 25%);
    background: #8fbdc0;
  }
  .navbar-text {
    padding-top: 0;
    padding-bottom: 0;
    width: 100%;
    background: #cde2e3;
  }
  a.login-btn {
    background: #80d7d8;
    color: #ffffff;
    padding: 8px 50px;
    border-radius: 0px;
    margin-left: 0;
    width: 100%;
    display: block;
  }
  .navbar-toggler {
    outline: 0 !important;
    filter: invert(1);
  }
  section.platform::before {
    height: 28%;
  }
  .mt-150 {
    margin-top: 100px;
  }
  section.founder-sec {
    padding-top: 120px;
  }
}
@media (max-width: 767px) {
  input.form-btn {
    padding: 10px;
    font-size: 1.2em;
    width: 100%;
  }
  .contact-us form {
    padding: 30px;
    border-radius: 40px;
    box-shadow: 0px 0px 15px #5a5a5a2e;
  }
  .contact-us form label {
    font-size: 1.1em;
  }
  .mt-120 {
    margin-top: 30px;
  }
  .header-bg:after {
    height: 114%;
  }
  section.platform {
    padding-bottom: 150px;
    padding-top: 40px;
  }
  section.platform::before {
    height: 15% !important;
  }
  section.peneeg {
    margin-top: -140px;
  }
  section.founder-sec {
    padding-top: 80px !important;
  }
}

.social-box {
  position: absolute;
  left: 0px;
  top: -10%;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  margin-top: -12px;
  text-align: center;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
}

.social-box {
  opacity: 1;
  top: 50%;
}

.container .founders-team .row .col-md-4 text-center .image .social-box a {
  position: relative;
  color: #ffffff;
  font-size: 14px;
  margin: 0px 6px;
}

.team-members .image-effect .social-box {
  position: absolute;
  left: 0px;
  top: -10%;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  margin-top: -12px;
  text-align: center;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
}
.team-members:hover .image-effect .social-box {
  opacity: 1;
  top: 42%;
}
.team-members .image-effect .social-box a {
  color: #fff !important;
}
.image-effect {
  overflow: hidden;
  border-radius: 300px;
  position: relative;
  width: fit-content;
  margin: auto;
}
.team-members .image-effect:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  display: block;
  opacity: 0;
  background-color: rgba(0, 186, 190, 0.6);
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
  transition: all 900ms ease;
  -webkit-transform: perspective(400px) rotateY(-90deg);
  -moz-transform: perspective(400px) rotateY(-90deg);
  -ms-transform: perspective(400px) rotateY(-90deg);
  -o-transform: perspective(400px) rotateY(-90deg);
  transform: perspective(400px) rotateY(-90deg);
  -webkit-transform-origin: center;
  -moz-transform-origin: center;
  -ms-transform-origin: center;
  -o-transform-origin: center;
  transform-origin: center;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}
.team-members:hover .image-effect:before {
  opacity: 1;
  -webkit-transform: perspective(400px) rotateY(0deg);
  -moz-transform: perspective(400px) rotateY(0deg);
  -ms-transform: perspective(400px) rotateY(0deg);
  -o-transform: perspective(400px) rotateY(0deg);
  transform: perspective(400px) rotateY(0deg);
}
